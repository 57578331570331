* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.gallery,
.contact-us {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.gallery {
  /* background-image: url(''); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {
  background-image: url('/public/images/driveways/D28.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: green;
  font-size: 100px;
  -webkit-text-stroke: 2px black;
  text-shadow: 
    -2px -2px 0 #000,  
     2px -2px 0 #000,  
    -2px  2px 0 #000,  
     2px  2px 0 #000;
  
  display: flex;
  flex-direction: column;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.contact-us > h3{
  color: #fff;
  font-size: 50px;
}

.contactusinfo{
  background-color: green;
  padding:3rem;
}

.details{
  align-self: center;
  justify-content: center;
  color: #fff;
  display:grid;
  
}
.checktext{
  color: #fff;
  background-color: green;
  padding: 10px;
  
}
.checktrust{
align-items: center;
justify-content: center;
display:flex;
flex-direction: row;
justify-content: center;
background-color: #a5d6a7;
padding: 10px;


}
.checkatrade{
width: 55%;

}
.trustatrade{
  width: 65%;
 
}

/* ConstructionServices.css */
.services-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: lightgreen;
}

.service-item {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 1rem;
  background-color: #2e7d32;
  
  
}

.service-image {
  width: 150px;
  height: 150px;
  border-radius: 4px;
  margin-right: 12px;
  object-fit: cover;
}

.service-content {
  display: flex;
  flex-direction: column;
}

.service-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #fff;
}

.service-description {
  font-size: 14px;
  color: #fff;
  margin: 4px 0 0;
}

.service-item-link{
  text-decoration: none;
}
.background{
  background-color: lightgreen;
}

 
  
