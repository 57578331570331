.photo-album {
    max-width: 100%;
    margin: 0rem auto;
    text-align: center;
    padding: 1rem 1rem 1rem;
    background-color: lightgreen;
    
    
  }
  

  .albumtitle{
    background-color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
    border-color: #fff;
    border-style: solid;
    border-width: 6px;
  }

  
  
  .photo-album h2 {
    font-size: 2rem;
    color: #fff;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 1rem;
  }
  
  .gallery-photo {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 1rem;
    transition: transform 0.2s ease;
  }
  
  .gallery-photo:hover {
    transform: scale(1.05);
  }
  
  /* Lightbox Styles */
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem; /* Optional: Add padding around the lightbox content */
    background-color: green;
  }
  
  .lightbox-content {
    position: relative;
    max-width: 90vw; /* Constrain the width to 90% of the viewport width */
    max-height: 90vh; /* Constrain the height to 90% of the viewport height */
    overflow: hidden;
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgreen;
  }
  
  .lightbox-content img {
    max-width: 100%; /* Image should not exceed lightbox content width */
    max-height: 100%; /* Image should not exceed lightbox content height */
    width: 60%;
    height: auto;
    border-radius: 8px;
    object-fit: contain; /* Ensures the image maintains aspect ratio */
    
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #333;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .close-btn:hover {
    background: green;
  }

  /* Arrow button styles */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.arrow:hover {
  background: rgba(0, 0, 0, 0.7);
}