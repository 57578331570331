.homepageinfo{
    padding: 3rem;
    background-color: green;
    position:flex;
    text-align: center;
    color: #fff;
    
  }
  .homepageinfo>p{
    
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .checktext1{
    color: #fff;
    background-color: green;
    padding: 10px;
    margin: 5px;
  }
  .checktrust1{
  align-self: center;
  justify-content: center;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: lightgreen;
  padding: 10px;
  margin: 5px;
  border-radius: 20px;
  }
  .checkatrade1{
  width:50%;
  }
  .trustatrade1{
   width: 60%;
  }
  
  